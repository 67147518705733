<template>
  <div>
    <v-progress-linear
      class="loader"
      indeterminate
      v-show="isLoading"
      color="loaderColor"
    />

    <div
      class="dictionaries"
      :class="{ ['is-loading']: isLoading, mobile: isMobile }"
    >
      <div class="dictionaries-header">
        <v-btn
          class="header-navigation-button white--text"
          color="navigationPageButtons"
          rounded
          v-can:DICT_READ
        >
          {{ $t("menu.category") }}
        </v-btn>
        <v-btn
          depressed
          rounded
          text
          router
          class="header-navigation-button"
          v-can:DICT_READ
          :to="{ name: 'organizations' }"
        >
          {{ $t("menu.organizations") }}
        </v-btn>
        <v-btn
          depressed
          rounded
          text
          router
          v-can:DICT_READ
          class="header-navigation-button"
          :to="{ name: 'departments' }"
        >
          {{ $t("menu.departments") }}
        </v-btn>
        <v-btn
          depressed
          rounded
          text
          router
          v-can:DICT_READ
          class="header-navigation-button"
          :to="{ name: 'defaultTags' }"
        >
          {{ $t("menu.defaultTags") }}
        </v-btn>
      </div>

      <div class="dictionaries-content">
        <div class="dictionaries-content-selectors">
          <div class="select-field">
            <span class="label">
              {{ $t("complaints.category").toUpperCase() }}
            </span>
            <BaseSelect
              :items="categories"
              item-text="text"
              item-value="id"
              v-model="selectedCategoryId"
            />
          </div>

          <div class="select-field">
            <span class="label">
              {{ $t("complaints.subcategory").toUpperCase() }}
            </span>
            <BaseSelect
              :items="subcategories"
              :disabled="!selectedCategoryId"
              item-text="text"
              item-value="id"
              v-model="selectedSubcategoryId"
            />
          </div>

          <div class="dictionary-params" v-if="dictionaryParams.length > 0">
            <span class="header">{{ $t("common.details") }}</span>
            <div
              class="warning-message"
              v-if="
                showWarningMessage &&
                dictionaryParams.length > maxNumberItemsToCheck
              "
            >
              {{
                isMobile
                  ? $t("dictionary.maxSingleParamSelected")
                  : $t("dictionary.maxMultiParamsSelected")
              }}
            </div>
            <v-checkbox
              v-for="(item, index) in dictionaryParams"
              :class="`dictionary-params-item${index === 0 ? ' first' : ''}`"
              color="fields"
              :key="index"
              :label="item.name"
              :value="item.id"
              v-model="selectedDictionaryParamIds"
              :disabled="
                isMaxOptionsSelected
                  ? !selectedDictionaryParamIds.includes(item.id)
                  : false
              "
            />
          </div>
        </div>

        <div
          :class="`dictionaries-content-details${isMobile ? ' mobile' : ''}`"
          v-if="selectedDictionaryParamIds.length > 0"
        >
          <DictionaryContentTable
            v-for="(item, index) in dictionaryParams.filter((param) =>
              selectedDictionaryParamIds.includes(param.id)
            )"
            displayMenuOptions
            :key="index"
            :readOnly="!hasUserPermissionsToEdit"
            :dictionaryParam="item"
            :dictionaryParamItems="dictionaryParamItems[item.name]"
            @setEditMode="setEditMode"
            @closeEditMode="closeEditMode"
            @addNewItem="addNewItem"
            @deleteItem="deleteItem"
            @editItem="editItem"
            @deleteAllItems="deleteAllItems"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { CPL_WRITE } from "@/models/restrictions.js";
import { EmitError, EmitSuccess } from "@/eventBus.js";

import DictionaryParamsService from "@/services/DictionaryParamsService";
import DictionaryService from "@/services/DictionaryService";

import DictionaryContentTable from "@/components/Dictionary/DictionaryContentTable";

export default {
  name: "DictionariesList",
  components: { DictionaryContentTable },
  mounted() {
    this.getCategories();
  },
  data() {
    return {
      categories: [],
      subcategories: [],
      dictionaryParams: [],
      dictionaryParamItems: {},
      selectedCategoryId: null,
      selectedSubcategoryId: null,
      selectedDictionaryParamIds: [],
      showWarningMessage: false,
      isMaxOptionsSelected: false,
      lastUpdateDate: {},
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("settings", ["isMobile", "getUserRestrictions"]),
    maxNumberItemsToCheck() {
      return this.isMobile ? 1 : 5;
    },
    hasUserPermissionsToEdit() {
      const userPermissions = this.getUserRestrictions
        ? this.getUserRestrictions.global
        : [];

      return userPermissions.some((item) => item.code === "DICT_WRITE");
    },
  },
  methods: {
    getCategories() {
      this.isLoading = true;
      DictionaryService.GetAllCategories(CPL_WRITE)
        .then((response) => {
          this.categories = response;

          if (response.length === 1) {
            this.selectedCategoryId = response[0].id;
          }
        })
        .finally(() => (this.isLoading = false));
    },
    getSubategories(categoryId) {
      this.isLoading = true;
      DictionaryService.GetSubcategories(categoryId)
        .then((response) => {
          this.subcategories = response;

          if (response.length === 1) {
            this.selectedSubcategoryId = response[0].id;
          }
        })
        .finally(() => (this.isLoading = false));
    },
    getDictionaryParams(categoryId) {
      this.isLoading = true;
      DictionaryService.GetAllParams(categoryId)
        .then((response) => {
          this.dictionaryParams = response;
        })
        .finally(() => (this.isLoading = false));
    },
    getDictionaryParamItems(dictionaryIds) {
      const dictionariesToFetch = this.dictionaryParams.filter((param) =>
        dictionaryIds.includes(param.dictId.toString())
      );

      const promises = [];
      dictionariesToFetch.forEach((dictionary) => {
        promises.push(
          DictionaryParamsService.GetDictionaryValues(dictionary.dictId, "0")
        );
      });

      this.isLoading = true;
      Promise.all(promises)
        .then((responses) => {
          const dictionaryItems = {};
          dictionariesToFetch.forEach((dictionary, index) => {
            dictionaryItems[dictionary.name] = responses[index].map(
              (response) => {
                return { ...response, editMode: false };
              }
            );
          });

          this.dictionaryParamItems = {
            ...this.dictionaryParamItems,
            ...dictionaryItems,
          };
        })
        .finally(() => (this.isLoading = false));
    },
    getLastUpdateDate() {
      const dictionariesIdsToFetch = this.dictionaryParams
        .filter((param) => this.selectedDictionaryParamIds.includes(param.id))
        .map((dictionary) => dictionary.dictId);

      this.isLoading = true;
      DictionaryParamsService.GetLastUpdate(dictionariesIdsToFetch)
        .then((response) => {
          const lastUpdate = {};

          response.forEach((item) => {
            lastUpdate[`${item.dictParamId}`] = item.updateTime;
          });

          this.lastUpdateDate = lastUpdate;
        })
        .finally(() => (this.isLoading = false));
    },
    setEditMode(item, paramName) {
      const newData = [];

      this.dictionaryParamItems[paramName].forEach((dataItem) => {
        if (dataItem.dictParamValueId === item.dictParamValueId) {
          newData.push({ ...dataItem, editMode: true });
        } else {
          newData.push({ ...dataItem });
        }
      });

      this.dictionaryParamItems = {
        ...this.dictionaryParamItems,
        [paramName]: newData,
      };
    },
    closeEditMode(item, paramName) {
      const newData = [];

      this.dictionaryParamItems[paramName].forEach((dataItem) => {
        if (dataItem.dictParamValueId === item.dictParamValueId) {
          newData.push({ ...dataItem, editMode: false });
        } else {
          newData.push(dataItem);
        }
      });

      this.dictionaryParamItems = {
        ...this.dictionaryParamItems,
        [paramName]: newData,
      };
    },
    addNewItem(newItemName, dictionaryId) {
      const data = {
        dictParamValueId: 0,
        dictValue: newItemName,
        dictParamId: dictionaryId,
      };

      this.isLoading = true;
      DictionaryParamsService.Create(data)
        .then(() => {
          this.getLastUpdateDate();
        })
        .finally(() => (this.isLoading = false));
    },
    deleteItem(itemId) {
      this.isLoading = true;
      DictionaryParamsService.Delete(itemId)
        .then(() => {
          this.getLastUpdateDate();
        })
        .finally(() => (this.isLoading = false));
    },
    editItem(newValue, item) {
      const data = {
        ...item,
        dictValue: newValue,
      };

      this.isLoading = true;
      DictionaryParamsService.Update(data)
        .then(() => {
          this.getLastUpdateDate();
        })
        .finally(() => (this.isLoading = false));
    },
    deleteAllItems(dictionaryId) {
      this.isLoading = true;
      DictionaryParamsService.DeleteAll(dictionaryId, dictionaryId)
        .then(() => {
          this.getLastUpdateDate();
          EmitSuccess(this.$t("dictionary.deleteAllSuccess"));
        })
        .catch(() => {
          EmitError(this.$t("dictionary.deleteError"));
        })
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    selectedCategoryId(value) {
      this.selectedSubcategoryId = null;
      this.subcategories = [];
      this.dictionaryParams = [];

      if (value) {
        this.getSubategories(value);
      }
    },
    selectedSubcategoryId(value) {
      this.dictionaryParams = [];
      this.selectedDictionaryParamIds = [];

      if (value) {
        this.getDictionaryParams(value);
      }
    },
    selectedDictionaryParamIds(newValue) {
      if (newValue.length < this.maxNumberItemsToCheck) {
        if (this.showWarningMessage) this.showWarningMessage = false;

        this.isMaxOptionsSelected = false;
      } else if (newValue.length >= this.maxNumberItemsToCheck) {
        this.showWarningMessage = true;
        this.isMaxOptionsSelected = true;
      }

      if (newValue.length > 0) {
        this.getLastUpdateDate();
      } else if (newValue.length === 0) {
        this.lastUpdateDate = {};
      }
    },
    lastUpdateDate(newValue, oldValue) {
      const outdatedDictionariesIds = [];

      Object.keys(newValue).forEach((key) => {
        if (!oldValue[key] || newValue[key] !== oldValue[key]) {
          outdatedDictionariesIds.push(key);
        }
      });

      if (outdatedDictionariesIds.length > 0) {
        this.getDictionaryParamItems(outdatedDictionariesIds);
      }
    },
    isMobile() {
      this.selectedDictionaryParamIds = [];
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  position: sticky;
  top: 64px;
  z-index: 2;
  margin: -40px -12px 40px -12px;
  width: auto;
}

.dictionaries {
  width: 100%;

  &:not(.mobile) {
    padding: 24px 12px 12px 12px;
  }

  &.is-loading {
    margin-top: -4px;
  }

  .dictionaries-header {
    margin-bottom: 30px;

    .header-navigation-button {
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }

  .dictionaries-content {
    display: flex;
    flex-direction: row;

    .dictionaries-content-selectors {
      width: 30%;
      max-width: 300px;
      margin-right: 40px;

      .select-field {
        margin-bottom: 15px;
      }

      .dictionary-params {
        .header {
          color: $text-color;
        }

        .warning-message {
          color: $error;
          margin: 10px 0;
          font-size: 13px;
        }

        .dictionary-params-item {
          margin-top: 0;

          &.first {
            margin-top: 10px;
          }
        }
      }
    }

    .dictionaries-content-details {
      width: 100%;
      height: calc(100vh - 170px);
      background-color: white;
      padding: 20px 0 20px 20px;
      overflow: auto;
      display: flex;
      flex-direction: row;

      &.mobile {
        padding: 10px;
      }
    }
  }
}
</style>
